<template>
  <div>
    <div class="top-box" v-if="isShow == 0">
      <p class="title_text">{{ title_name }}</p>
      <a-button
          type="primary"
          @click="SubmitButtons"
          v-if="listName == 'script' || listName == 'template' || listName == 'product'"
          :disabled="status === 9"
      >{{ isButton }}
      </a-button>
    </div>
    <div class="form-box" v-if="isShow > 0">
      <a-form-model
          ref="search_form"
          :model="search_form"
          layout="inline"
      >
        <a-row style="padding-bottom: 15px">
          <a-col :span="8">
            <a-form-model-item label="品 牌">
              <a-select
                  v-model="search_form.principalId"
                  show-search
                  option-filter-prop="children"
                  allow-clear
                  placeholder="请输入关键字搜索"
                  :filter-option="filterOption"
                  style="width: 260px"
              >
                <a-select-option :value="item.id" v-for="item in principalList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="状 态">
              <a-select
                  v-model="search_form.status"
                  allow-clear
                  placeholder="请选择"
                  style="width: 260px"
              >
                <a-select-option :value="item.id" v-for="item in statusList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="isShow == 1 || isShow == 2">
            <a-form-model-item
                label="脚本名称"
            >
              <a-input
                  placeholder="请输入"
                  v-model="search_form.scriptName"
                  style="width: 260px;"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="isShow == 3">
            <a-form-model-item
                label="模板名称"
            >
              <a-input
                  placeholder="请输入"
                  v-model="search_form.guideshootName"
                  style="width: 260px;"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 15px">
          <template v-if="isShow == 1">
            <a-col :span="8">
              <a-form-model-item
                  label="脚本日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'scriptSubmit')"
                    style="width: 220px"
                    v-model="time1"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="编导"
              >
                <a-input
                    placeholder="请输入"
                    v-model="search_form.directorName"
                    style="width: 260px;"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="完成日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'finish')"
                    style="width: 240px"
                    v-model="time6"
                />
              </a-form-model-item>
            </a-col>
          </template>
          <template v-if="isShow == 2">
            <a-col :span="8">
              <a-form-model-item
                  label="摄影"
              >
                <a-input
                    placeholder="请输入"
                    v-model="search_form.photographyName"
                    style="width: 260px;"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="剪辑"
              >
                <a-input
                    placeholder="请输入"
                    v-model="search_form.editorName"
                    style="width: 260px;"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="拍摄日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'photoDate')"
                    style="width: 220px"
                    v-model="time2"
                />
              </a-form-model-item>
            </a-col>
          </template>
          <template v-if="isShow == 3">
            <a-col :span="8">
              <a-form-model-item
                  label="上传日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'uploadDate')"
                    style="width: 220px"
                    v-model="time"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="测试日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'testDate')"
                    style="width: 220px"
                    v-model="time3"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                  label="完成日期"
              >
                <a-range-picker
                    :placeholder="['开始日期', '结束日期']"
                    format="YYYY-MM-DD"
                    inputReadOnly
                    @change="onChangeDate(arguments, 'finish')"
                    style="width: 220px"
                    v-model="time4"
                />
              </a-form-model-item>
            </a-col>
          </template>
        </a-row>
        <a-row style="padding-bottom: 15px">
          <a-col :span="8" v-if="isShow == 2">
            <a-form-model-item
                label="剪辑日期"
            >
              <a-range-picker
                  :placeholder="['开始日期', '结束日期']"
                  format="YYYY-MM-DD"
                  inputReadOnly
                  @change="onChangeDate(arguments, 'editDate')"
                  style="width: 220px"
                  v-model="time5"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="isShow == 2">
            <a-form-model-item
                label="完成日期"
            >
              <a-range-picker
                  :placeholder="['开始日期', '结束日期']"
                  format="YYYY-MM-DD"
                  inputReadOnly
                  @change="onChangeDate(arguments, 'finish')"
                  style="width: 220px"
                  v-model="time6"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 15px;display: flex;justify-content: flex-end">
          <a-button class="mr-10" @click="onClickButton('reset')">重置</a-button>
          <a-button class="mr-10" :loading="exporting" @click="onClickButton('export')">导出</a-button>
          <a-button type="primary" class="mr-10" @click="onClickButton('search')">查询</a-button>
          <a-button
              type="primary"
              class="mr-10"
              @click="onClickButton('photography')"
              v-if="isShow == 2">
            批量添加摄影场地
          </a-button>
          <a-button
              type="primary"
              class="mr-10"
              @click="onClickButton('myTask')"
              v-if="isShow == 1 ||isShow == 2">
            我的任务
          </a-button>
          <a-button
              type="primary"
              class="mr-10"
              @click="onClickButton('group')"
              v-if="isShow == 3">
            批量添加分组
          </a-button>
          <a-button
              type="danger"
              class="mr-10"
              @click="onClickButton('status',1)"
              v-if="isShow == 1 || isShow == 2">
            待分配任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',5)" v-if="isShow == 1">待定稿任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',3)" v-if="isShow == 2">待剪辑任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',7)" v-if="isShow == 2">待审核任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',1)" v-if="isShow == 3">待上传任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',2)" v-if="isShow == 3">待调整任务
          </a-button>
          <a-button type="danger" class="mr-10" @click="onClickButton('status',4)" v-if="isShow == 3">待上线任务
          </a-button>
        </a-row>
      </a-form-model>
    </div>
    <a-table
        :columns="columns"
        :data-source="contentList"
        :pagination="false"
        :rowKey="(record) => record.id"
        :loading="loading"
        bordered
        :scroll="scroll"
        :row-selection="listName == 'template' || listName == 'product'? rowSelection:null"
        class="a-tables-scroll"
    >
      <div slot="status" slot-scope="text, record" style="cursor: pointer">
        <a-popover placement="bottomLeft">
          <div v-if="record.status == 2 || record.status == 3 || record.status == 4 ">
            <a-badge color="blue"/>
            <span style="color:#2A90F4;">
              {{ record.statusName }}
              <!--{{-->
              <!--    record.status == 2 ? "策划中" : record.status == 3 ? "内审中" : record.status == 4 ? "修改中" : ""-->
              <!--  }}-->
          </span>
          </div>
          <div v-if="record.status == 1 || record.status == 5">
            <a-badge color=" #ff4446
        "/>
            <span style="color: #ff4446">
               {{ record.statusName }}
              <!--{{-->
              <!--    record.status == 1 ? "待分配" : record.status == 5 ? "待定稿" : ""-->
              <!--  }}-->
          </span>
          </div>
          <div v-if="record.status == 7 || record.status == 8 || record.status == 9">
            <a-badge color="#666666"/>
            <span style="color: #666666">
               {{ record.statusName }}
              <!--{{-->
              <!--    record.status == 7 ? "备用/待定" : record.status == 8 ? "内审退回" : record.status == 9 ? "客审退回" : ""-->
              <!--  }}-->
          </span>
          </div>
          <div v-if="record.status == 6">
            <a-badge color="#0bc9b9"/>
            <span style="color: #0bc9b9"> {{ record.statusName }}</span>
          </div>
          <template #content>
            <p v-for="(item,i) in statusList" :key="i" style="cursor: pointer" @click="changePutStatus(item.id,record)">
              <a-badge :color="item.color"/>
              <a-button
                  type="link"
                  :style="{color: item.color}"
                  :disabled="record.status == item.id  || record.status == 6">
                {{ item.name }}
              </a-button>
              <!--<span :style="{color: item.color}">{{ item.name }}</span>-->
            </p>
          </template>
        </a-popover>
      </div>
      <div slot="productStatus" slot-scope="text, record" style="cursor: pointer">
        <a-popover placement="bottomLeft">
          <div v-if="record.status == 2 || record.status == 5 || record.status == 4  || record.status == 6 ">
            <a-badge color="blue"/>
            <span style="color:#2A90F4;">
               {{ record.statusName }}
              <!--{{-->
              <!--  record.status == 2 ? "拍摄中" : record.status == 5 ? "修改中" : record.status == 4 ? "剪辑中" : record.status == 6 ? "内审中" : ""-->
              <!--}}-->
          </span>
          </div>
          <div v-if="record.status == 1 || record.status == 3 || record.status == 7">
            <a-badge color=" #ff4446
        "/>
            <span style="color: #ff4446">
               {{ record.statusName }}
              <!--{{-->
              <!--  record.status == 1 ? "待分配" : record.status == 3 ? "待剪辑" : record.status == 7 ? "待审核" : ""-->
              <!--}}-->
          </span>
          </div>
          <div v-if="record.status == 9">
            <a-badge color="#666666"/>
            <span style="color: #666666"> {{ record.statusName }}</span>
          </div>
          <div v-if="record.status == 8">
            <a-badge color="#0bc9b9"/>
            <span style="color: #0bc9b9"> {{ record.statusName }}</span>
          </div>
          <template #content>
            <p v-for="(item,i) in statusList" :key="i" style="cursor: pointer" @click="changePutStatus(item.id,record)">
              <a-badge :color="item.color"/>
              <a-button
                  type="link"
                  :style="{color: item.color}"
                  :disabled="record.status == item.id  || record.status == 8">
                {{ item.name }}
              </a-button>
              <!--<span :style="{color: item.color}">{{ item.name }}</span>-->
            </p>
          </template>
        </a-popover>
      </div>
      <div slot="guideshootStatus" slot-scope="text, record" style="cursor: pointer">
        <a-popover placement="bottomLeft">
          <div v-if="record.status == 3  || record.status == 7">
            <a-badge color="blue"/>
            <span style="color:#2A90F4;">{{ record.statusName || "" }}</span>
          </div>
          <div v-if="record.status == 1 || record.status == 2 || record.status == 4">
            <a-badge color=" #ff4446
        "/>
            <span style="color: #ff4446">
            {{ record.statusName || "" }}
          </span>
          </div>
          <div v-if="record.status == 6">
            <a-badge color="#666666"/>
            <span style="color: #666666">{{ record.statusName || "" }}</span>
          </div>
          <div v-if="record.status == 5">
            <a-badge color="#0bc9b9"/>
            <span style="color: #0bc9b9">{{ record.statusName || "" }}</span>
          </div>
          <template #content>
            <p v-for="(item,i) in statusList" :key="i" style="cursor: pointer" @click="changePutStatus(item.id,record)">
              <a-badge :color="item.color"/>
              <a-button
                  type="link"
                  :style="{color: item.color}"
                  :disabled="record.status == item.id || record.status == 5">
                {{ item.name }}
              </a-button>
              <!--<span :style="{color: item.color}">{{ item.name }}</span>-->
            </p>
          </template>
        </a-popover>
      </div>
      <div slot="names" slot-scope="text, record">
        <a-tooltip>
          <template #title>{{ record.scriptName }}</template>
          <p class="scriptName" @click="actionButtons(3,record)">
            {{ record.scriptName }}</p>
        </a-tooltip>
      </div>
      <div slot="version" slot-scope="text, record,index">
        <p style="color: #40a9ff;cursor: pointer" @click="actionButtons(4,record)">
          第{{ record.version }}版</p>
      </div>
      <div slot="fhlGuideshootStatus" slot-scope="text, record,index">
        <span>{{ record.fhlGuideshootStatus == 1 ? "已上架" : record.fhlGuideshootStatus == 2 ? "未上架" : "" }}</span>
      </div>
      <div slot="difficulty" slot-scope="text, record">
        <p>{{ record.difficulty == 1 ? "高" : record.difficulty == 2 ? "中" : record.difficulty == 3 ? "低" : "" }}</p>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a @click="actionButtons(1,record)"
           v-if="listName=='script' || listName=='product' || listName=='template'">详情</a>
        <a @click="actionButtons(2,record)" v-if="listName=='WorkOrderManagementPage'">编辑</a>
      </div>
    </a-table>

    <!-- 分页配置 -->
    <base-pagination
        :currentPage="pagination.page_num"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        :options="['10', '30', '50']"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    >
    </base-pagination>

    <!--添加/编辑脚本-->
    <a-modal v-model="scriptModal" :title="modalTitle" @ok="handleOk" width="40%">
      <a-form-model
          ref="formRef"
          :model="formModel"
          layout="inline"
          :rules="formModelRules"
      >
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="编导" prop="directorId">
              <a-select
                  v-model="formModel.directorIdList"
                  allow-clear
                  label-in-value
                  placeholder="请选择"
                  style="width: 400px"
                  @change="changeDirectorId"
              >
                <a-select-option :value="item.id" v-for="item in directorIdList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="脚本类型">
              <a-input
                  style="width: 400px"
                  placeholder="请输入"
                  v-model="formModel.scriptType"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-form-model-item label="脚本难度">
              <a-select
                  v-model="formModel.difficulty"
                  allow-clear
                  placeholder="请选择"
                  style="width: 400px"
              >
                <a-select-option :value="item.id" v-for="item in difficultyList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding-bottom: 12px">
          <a-col>
            <a-col>
              <a-form-model-item label="车系">
                <a-select
                    v-model="formModel.seriesName"
                    allow-clear
                    show-search
                    placeholder="请选择"
                    style="width: 400px"
                    :filter-option="filterOption"
                >
                  <a-select-option value="0">
                    不限
                  </a-select-option>
                  <a-select-option :value="item.name" v-for="item in seriesId_list" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!--批量添加分组-->
    <groupModal :title="'批量添加分组'" :visible="templateGroupModal" :keys="selectedRowKeys"
                @handleCancel="handleCancelGroupModal"/>

    <!--查看成片-->
    <preview-modal
        :visible="preview_visible"
        :preview-src="preview_src"
        width="80%"
        @cancel="handlePreviewCancel"
    />

    <!--批量添加摄影场地-->
    <a-modal v-model="photographyModal" :title="'添加摄影场地'" @ok="handlePhotographyOk" width="40%">
      <div class="photography-box">
        <span>摄影场地：</span>
        <a-input
            placeholder="请输入"
            v-model="address"
            style="width: 260px;"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import _api from "@/api/contentProduction.js";
import groupModal from "./groupModal";
import { cloneDeep } from "lodash-es";
import { downloadExcel } from "@/utils";
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";


export default {
  name: "template_list",
  data() {
    return {
      groupModel: {},
      scriptModal: false,
      formModel: {},
      formModelRules: {
        directorId: [
          {required: true, message: "请选择编导", trigger: "change"}
        ],
      },
      directorIdList: [],
      seriesId_list: [],
      difficultyList: [
        {
          id: 1,
          name: "高"
        },
        {
          id: 2,
          name: "中"
        },
        {
          id: 3,
          name: "低"
        },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      templateGroupModal: false,
      search_form: {},
      time: [],
      time1: [],
      time2: [],
      time3: [],
      time4: [],
      time5: [],
      time6: [],
      exporting: false,
      preview_visible: false,
      preview_src: "",
      address: "",
      photographyModal: false
    };
  },
  props: {
    principalList: {
      type: Array,
      default: () => []
    },
    statusList: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      required: true,
      default: () => []
    },
    contentList: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
    },
    title_name: {
      type: String,
      default: "模板内容"
    },
    isButton: {
      type: String,
      default: "添加"
    },
    modalTitle: {
      type: String,
      default: "添加脚本任务"
    },
    listName: {
      type: String,
    },
    order_id: {
      type: [String, Number]
    },
    principalId: {
      type: [String, Number]
    },
    principalName: {
      type: String
    },
    pagination: {
      type: Object,
      default() {
        return {
          page_num: 1,
          page_size: 10,
          total: 0
        };
      }
    },
    isShow: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
    },
    scroll: {
      type: Object,
      default() {
        return {y: 500, x: 1000};
      }
    },
  },
  components: {
    groupModal,
    previewModal
  },
  computed: {
    rowSelection() {
      const {selectedRowKeys, selectedRows} = this;
      return {
        selectedRowKeys,
        selectedRows,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRowKeys);
          this.selectedRowKeys = selectedRowKeys;
          this.selectedRows = selectedRows;
        }
      };
    }
  },
  mounted() {
  },
  methods: {
    getSeriesIdList() {
      _api.getSeriesIdList({principalId: this.principalId}).then(res => {
        if(res.code == 200) {
          this.seriesId_list = res.data || [];
        } else {
          this.seriesId_list = [];
        }
      });
    }, // 车系列表
    getAuthUsers() {
      const params = {
        page_no: 1,
        page_size: 999
      };
      _api.getAuthUsers(params).then(res => {
        if(res.code == 200) {
          this.directorIdList = res.data.list || [];
        } else {
          this.directorIdList = [];
          this.$message.error(res.message || "获取编导下拉数据失败");
        }
      });
    }, // 编导下拉数据
    actionButtons(type, row) {
      switch(type) {
        case 1:
          this.$router.push({
            name: "TaskManagementDetail",
            query: {
              type: this.listName,
              taskId: row.id,
            }
          });
          break;
        case 2:
          this.$emit("actionChange", row);
          break;
        case 3:
          if(row.scriptAttachUrl) {
            let str = row.scriptAttachUrl.split("?")[0];
            const isWX = str.split("/")[2];
            const isImg = str.split(".")[3];
            if(isImg && isImg == "webp") {
              this.preview_visible = true;
              this.preview_src = row.scriptAttachUrl || "";
            } else if(isWX && isWX == "doc.weixin.qq.com") {
              window.open(row.scriptAttachUrl, "_target");
            } else {
              let URL = encodeURIComponent(row.scriptAttachUrl);
              window.open(`http://view.officeapps.live.com/op/view.aspx?src=${ URL }`, "_target");
            }
          } else {
            this.$message.error("当前脚本无法查看");
          }

          break;
        case 4:
          console.log(row);
          this.preview_visible = true;
          this.preview_src = row.productAttachUrl || "";
          break;
      }
    },// 操作
    handlePaginationChange(currentPage, pageSize) {
      this.$emit("pagination-change", currentPage, pageSize);
    },
    SubmitButtons() {
      switch(this.listName) {
        case "script":
          this.scriptModal = true;
          this.formModel = {};
          this.getAuthUsers();
          this.getSeriesIdList();
          break;
        case "template":
          if(!this.selectedRowKeys.length) {
            this.$message.error("请先选择模板任务");
            return false;
          }
          this.templateGroupModal = true;
          this.templateGroupTitle = "添加可见分组";
          break;
        case "product":
          if(!this.selectedRowKeys.length) {
            this.$message.error("请先选择成片任务");
            return false;
          }
          this.photographyModal = true;
          this.address = "";
          break;
      }
    },
    handleOk() {
      this.$refs.formRef.validate((valid) => {
        if(!valid) {
          return;
        }
        let params = {
          orderId: this.order_id,
          directorId: this.formModel.directorId,
          directorName: this.formModel.directorName,
          scriptType: this.formModel.scriptType,
          difficulty: this.formModel.difficulty,
          seriesName: this.formModel.seriesName,
          principalId: this.principalId,
          principalName: this.principalName,
          status: 1,
        };
        this.postOrderScriptTask("脚本添加", params);
      });
    },// 添加脚本任务
    handlePhotographyOk() {
      let params = {
        ids: this.selectedRowKeys,
        photoAddr: this.address,
      };
      _api.putPhotoAddress(params).then(res => {
        if(res.code == 200) {
          this.$emit("loaded", "product");
          this.$message.success("添加成功");
        } else {
          this.$message.error(res.message || "添加失败");
        }
        this.selectedRowKeys = [];
        this.selectedRows = [];
        this.photographyModal = false;
        this.address = "";
      });
    },// 添加摄影场地
    changeDirectorId(val) {
      this.formModel.directorId = val;
      this.formModel.directorId = val.key;
      this.formModel.directorName = val.label;
      // director_name
    },// 编导选择
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }, // 过滤
    postOrderScriptTask(text, params) {
      _api.postOrderScriptTask(params).then(res => {
        if(res.code == 200) {
          this.scriptModal = false;
          this.$emit("loaded", "script");
          this.$message.success(`${ text }成功`);
        } else {
          this.$message.error(res.message || `${ text }失败`);
        }
      });
    }, // 添加/修改脚本任务
    postOrderProductTask(text, params) {
      _api.postOrderProductTask(params).then(res => {
        if(res.code == 200) {
          this.scriptModal = false;
          this.$emit("loaded", "product");
          this.$message.success(`${ text }成功`);
        } else {
          this.$message.error(res.message || `${ text }失败`);
        }
      });
    }, // 添加/修改成片任务
    postOrderTemplateTask(text, params) {
      _api.postOrderTemplateTask(params).then(res => {
        if(res.code == 200) {
          this.scriptModal = false;
          this.$emit("loaded", "template");
          this.$message.success(`${ text }成功`);
        } else {
          this.$message.error(res.message || `${ text }失败`);
        }
      });
    }, // 更新模板状态
    updateStatus(text, params) {
      _api.updateStatus(params, this.listName).then(res => {
        if(res.code == 200) {
          this.$emit("loaded", this.listName);
          this.$message.success(`${ text }成功`);
        } else {
          this.$message.error(res.message || `${ text }失败`);
        }
      });
    }, // 更新脚本/成片状态
    changePutStatus(itemId, record) {
      let params = {
        id: record.id,
        status: itemId,
      };
      switch(this.listName) {
        case "script":
          this.updateStatus("状态修改", params);
          break;
        case "product":
          this.updateStatus("状态修改", params);
          break;
        case "template":
          params.guideshootName = record.guideshootName || "";
          this.postOrderTemplateTask("状态修改", params);
          break;
      }
    },//切换状态
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },// 多选
    handleCancelGroupModal() {
      this.selectedRowKeys = [];
      this.templateGroupModal = false;
      this.$emit("loaded", "template");
    },// 添加分组
    onChangeDate(dates, type) {
      let start_time = "";
      let end_time = "";
      const timeArray = dates[1];
      if(timeArray) {
        start_time = timeArray[0] ? this.$moment(timeArray[0]).format("YYYY-MM-DD") : "";
        end_time = timeArray[1] ? this.$moment(timeArray[1]).format("YYYY-MM-DD") : "";
      }
      switch(type) {
        case "scriptSubmit":
          this.search_form.scriptDateBegin = start_time;
          this.search_form.scriptDateEnd = end_time;
          break;
        case "uploadDate":
          this.search_form.uploadDateStart = start_time;
          this.search_form.uploadDateEnd = end_time;
          break;
        case "photoDate":
          this.search_form.photoDateBegin = start_time;
          this.search_form.photoDateEnd = end_time;
          break;
        case "testDate":
          this.search_form.testDateStart = start_time;
          this.search_form.testDateEnd = end_time;
          break;
          // case "releaseDate":
          //   this.search_form.releaseDateStart = start_time;
          //   this.search_form.releaseDateEnd = end_time;
          //   break;
        case "editDate":
          this.search_form.editDateBegin = start_time;
          this.search_form.editDateEnd = end_time;
          break;
        case "finish":
          this.search_form.finishDateStart = start_time;
          this.search_form.finishDateEnd = end_time;
          break;
      }
    }, // 日期选择框
    //搜索
    async exportData() {
      this.exporting = true;
      try {
        const params = cloneDeep(this.search_form);
        const type = this.listName == "template" ? "guideshoot" : this.listName;
        const fileName = this.listName == "script" ? "脚本任务" : this.listName == "product" ? "成片任务" : this.listName == "template" ? "模板任务" : "";
        const res = await _api.exportData(params, type);
        console.log(res);
        downloadExcel(res, fileName);
        this.exporting = false;
      } catch(e) {
        console.log(e);
        this.exporting = false;
      }
    },
    onClickButton(type, id = undefined) {
      switch(type) {
        case "reset":
          this.selectedRowKeys = [];
          this.search_form = {};
          this.time = [];
          this.time1 = [];
          this.time2 = [];
          this.time3 = [];
          this.time4 = [];
          this.time5 = [];
          this.time6 = [];
          this.$emit("loadedList", this.search_form, this.listName);
          break;
        case "export":
          this.exportData();
          break;
        case "search":
          this.selectedRowKeys = [];
          this.$emit("loadedList", this.search_form, this.listName);
          break;
        case "myTask":
          this.selectedRowKeys = [];
          this.search_form = {};
          this.time = [];
          this.time1 = [];
          this.time2 = [];
          this.time3 = [];
          this.time4 = [];
          this.time5 = [];
          this.search_form.myTask = this.$root.user.realname;
          this.$emit("loadedList", this.search_form, this.listName);
          break;
        case "group":
          if(!this.selectedRowKeys.length) {
            this.$message.error("请先选择模板任务");
            return false;
          }
          this.templateGroupModal = true;
          this.templateGroupTitle = "添加可见分组";
          break;
        case "status":
          this.selectedRowKeys = [];
          this.search_form = {};
          this.time = [];
          this.time1 = [];
          this.time2 = [];
          this.time3 = [];
          this.time4 = [];
          this.time5 = [];
          this.time6 = [];
          this.$set(this.search_form, "status", id);
          this.$emit("loadedList", this.search_form, this.listName);
          break;
        case "photography":
          if(!this.selectedRowKeys.length) {
            this.$message.error("请先选择成片任务");
            return false;
          }
          this.photographyModal = true;
          this.address = "";
          break;
      }
    },
    handlePreviewCancel() {
      this.preview_src = "";
      this.preview_visible = false;
    },//关闭成片/脚本预览
  },
};
</script>

<style scoped lang="less">
.title_text {
  font-size: 16px;
  font-weight: bold;
}

.top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px
}

.table-box {
  padding-top: 20px;
}

.form-box {
  padding: 20px 0;
}

.mr-10 {
  margin-right: 15px;
}

.scriptName {
  width: 160px;
  color: #40a9ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.photography-box {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  span {
    padding-right: 10px;
    padding-left: 20px;
  }
}

.a-tables-scroll /deep/ .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  ul::-webkit-scrollbar { // 整个滚动条.
    width: 6px;
  }

  &::-webkit-scrollbar-thumb { // 滚动条上的滚动滑块
    border-radius: 8px;
    background-color: #7f7f7f;
  }

  &::-webkit-scrollbar-corner { // 当同时有垂直滚动条和水平滚动条时交汇的部分
    background_color: #242832;
  }

  &::-webkit-scrollbar-track { // 滚动条轨道
    border-radius: 0;
    background_color: #242832;
  }

}
</style>
